@import "../../css/Fonts.module.css";
.navbar {
    background-color: #0c0910;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
}

.navbarFill {
    height: 3.333125em;
}

.navbarContainer {
    display: block;
    margin-left: 2%;
    margin-right: 2%;
}

.navbarColumn {
    width: 50%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.linkContainer {
    display: inline-grid;
    float: right;
    position: relative;
    text-decoration: none;
    top: -4px;

    &::after {
        position: absolute;
        bottom: -4px;
        left: 0;
        content: '';
        width: 100%;
        height: 4px;
        background: var(--red);
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform .3s;
    }
    &:hover::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
}

.anchorContainer {
    display: table;
    height: 4em;
    cursor: pointer;
    float: right;
    margin: 0 0.5em 0 0.5em;
    position: relative;
    transition: all 0.1s ease-in-out;
    top: 4px;
    background: none;
    border: none;

    &:hover {
        top: 6px;
    }
}

.link {
    font-family: 'Ropa Sans', sans-serif;
    user-select: none;
    margin: 0;
    cursor: pointer;

    & h1 {
        font-size: 1.5em;
    }

    & svg {
        font-size: 1.5em;
    }
}

.linkIcon {
    color: var(--platinum);
    float: right;
    font-size: 1.6475em;
    margin: 0.18em 0 0.18em 0;
    padding: 0.6em 0.5em 0.6em 0.5em;
    transition: all 0.1s ease-in-out;

    &:hover {
        padding: 0.8em 0.5em 0.4em 0.5em;
    }
}

.logo {
    font-family: 'Ropa Sans', sans-serif;
    font-size: 2.5em;
    font-weight: bold;
    height: 100%;
    margin: 0;
    float: left;
    user-select: none;
}

.logoControl {
    width: auto !important;
}

.caret {
    font-family: 'Ropa Sans', sans-serif;
    font-size: 2.5em;
    font-weight: bold;
    user-select: none;
}

.caretBlink {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
}

@media screen and (max-width: 875px) {
    .navbar .burgerContainer:not(:first-child) {display: none}
    .navbar .menuLinks {display: none;}
    /*.navbar .burgerMenu {display: none;}*/
    .navbar a.icon {
        float: right;
        display: block;
    }
}

@media screen and (min-width: 876px) {
    .navbar .burgerContainer {display: none}
}

.menuLinks {
    display: inline;
}

.burgerContainer {
    float: right;
    position: relative;
    transition: all 0.1s ease-in-out;
    background: none;
    border: none;
    display: table;
    height: 3.3em;
    margin: 0 0.5em 0 0.5em;
}

.burgerCenter {
    display: table-cell;
    vertical-align: middle;
}

.burgerIcons {
    display: table-cell;
    vertical-align: middle;
    height: 1.5em;

    width: 1.5em;
    position: relative;
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    &:hover span {
        background: var(--red);
    }
}

.burgerIcons span {
    display: block;
    position: absolute;
    height: 0.2em;
    width: 100%;
    background: var(--platinum);
    border-radius: 1em;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.burgerIcons span:nth-child(1) {
    top: 0;
}

.burgerIcons span:nth-child(2),.burgerIcons span:nth-child(3) {
    top: 0.6em;
}

.burgerIcons span:nth-child(4) {
    top: 1.2em;
}

.open:hover span:nth-child(2), .open:hover span:nth-child(3) {
    background: var(--platinum);
}

.open span:nth-child(1) {
    top: 0.6em;
    width: 0;
    left: 50%;
}

.open span:nth-child(2) {
    transform: rotate(45deg);
    background: var(--red);
}

.open span:nth-child(3) {
    transform: rotate(-45deg);
    background: var(--red);
}

.open span:nth-child(4) {
    top: 1em;
    width: 0;
    left: 50%;
}

.burgerMenu {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
}

.burgerWrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease-out;
}

.burgerItem {
    width: 100%;

    & .anchorContainer {
        display: flex;
        justify-content: center;
    }
}