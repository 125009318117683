.defaultStyles {
    display: inline-block;

    & h1 {
        font-family: 'Ropa Sans', sans-serif;
        font-size: 3em;
        margin: 0.1em auto;
        padding: 0 0.5em;
        width: fit-content;
    }

    & h2 {
        font-family: 'Ropa Sans', sans-serif;
        font-size: 2.1em;
        margin: 0.1em 0;
    }

    & p {
        font-family: 'Space Mono', monospace;
        font-size: 1.05em;
        text-align:justify;
    }

    & ul {
        padding-left: 1em;
    }

    & li {
        margin: 0.5em 0;
        color: var(--platinum);
        font-family: 'Space Mono', monospace;
        font-size: 1.05em;
    }
}

.roundedColumn {
    border-radius: 0 1em;
}

.highlightBlue {
    color: var(--blue);
}

.highlightRed {
    color: var(--red);
}

.whiteColumn {
    background-color: var(--platinum);
    & p {
        color: var(--night);
    }
}

.redColumn {
    background-color: var(--red);
    & p {
        color: var(--night);
    }
}

.blueColumn {
    background-color: var(--blue);
    & p {
        color: var(--night);
    }
}

.rowHeader {
    color: var(--platinum) !important;
    font-size: 3.5em !important;
    width: 100%;
    text-align: center;
    position: relative;
}

.columnDark {
    & h1 {
        color: var(--red);
    }

    & p, & h2 {
        color: var(--platinum);
    }
}

.headerRed {
    color: var(--red);
}

.columnImage {

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}