.skillboxWrapper {
    display: inline-grid;
    width: 40%;
    margin: 0 5%;
}

.iconWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 7.3em);
    grid-gap: 0.5em;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-bottom: 2.5em;
}

.icon {
    display: block;
    width: 7.3em;
    height: 7.3em;
    text-align: center;
    background: var(--lightRaisin);
    border-radius: 0 0.5em 0 0.5em;

    & img {
        width: 4.5em;
        height: 4.5em;
        margin: 0.5em;
        object-fit: contain;
    }

    & h3 {
        width: 100%;
        margin: 0;
        font-family: 'Space Mono', monospace;
        font-size: 1.05em;
        color: var(--platinum);
    }
}