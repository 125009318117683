.galleryWrapper {
    background: var(--raisin);
    padding: 1em;
    border-radius: 1em;
}

.galleryContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
}

.buttonPrev, .buttonNext{
    position: absolute;
    font-size: 4rem;
    cursor: pointer;
    user-select: none;
    color: var(--blue);
    transition: all 0.1s ease-in-out;
}

.buttonPrev {
    left: 0.15em;
}

.buttonNext {
    right: 0.15em;
}

.buttonPrev:hover, .buttonNext:hover {
    color: var(--platinum);
}

.galleryTrack {
    position: relative;
    width: 85%;
    height: 22em;
    overflow: hidden;
    background-color: var(--night);
}

.slideImage {
    position: absolute;
    background-size: cover;
    object-fit: cover;
    width: 101%;
    height: 22.1em;
    top: -1px;
}

.galleryFooter {
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.bubbleOuter {
    cursor: pointer;
    border : 0.5em solid var(--night);
    border-radius: 0.5em;
    transition: all 0.3s ease-in-out;

    &:hover {
        border-color: var(--platinum) !important;
    }
}

.leftOut {
    -webkit-animation: slide-left-out .5s 1 forwards;
    -moz-animation: slide-left-out .5s 1 forwards;
    -ms-animation: slide-left-out .5s 1 forwards;
    -o-animation: slide-left-out .5s 1 forwards;
    animation: slide-left-out .5s 1 forwards;
}

.leftIn {
    -webkit-animation: slide-left-in .5s 1 forwards;
    -moz-animation: slide-left-in .5s 1 forwards;
    -ms-animation: slide-left-in .5s 1 forwards;
    -o-animation: slide-left-in .5s 1 forwards;
    animation: slide-left-in .5s 1 forwards;
}

.rightOut {
    -webkit-animation: slide-right-out .5s 1 forwards;
    -moz-animation: slide-right-out .5s 1 forwards;
    -ms-animation: slide-right-out .5s 1 forwards;
    -o-animation: slide-right-out .5s 1 forwards;
    animation: slide-right-out .5s 1 forwards;
}

.rightIn {
    -webkit-animation: slide-right-in .5s 1 forwards;
    -moz-animation: slide-right-in .5s 1 forwards;
    -ms-animation: slide-right-in .5s 1 forwards;
    -o-animation: slide-right-in .5s 1 forwards;
    animation: slide-right-in .5s 1 forwards;
}

@keyframes slide-right-in {
    from {
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-left-in {
    from {
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-right-out {
    from {
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes slide-left-out {
    from {
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}