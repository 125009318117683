.buttonWrapper {
    width: 100%;
}

.defaultButtonStyle {
    border: none;
    border-radius: 0 0.5em;
    cursor: pointer;
    position: relative;
    transition: all 0.1s ease-in;
    margin: 0.5em auto;
    display: block;

    & h1 {
        margin: 0.1em 0.5em;
        font-family: 'Space Mono', monospace !important;
        font-size: 1.5em !important;
        font-weight: normal;
        position: relative;
        top: 0;
        transition: all 0.1s ease-in-out;
    }

    &:hover h1 {
        top: 2px;
    }

    &::after {
        border-radius: 0 0 0 0.5em;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 4px;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform .3s;
    }
    &:hover::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
}

.whiteButton {
    background-color: var(--platinum);
    & h1 {
        color: var(--night);
    }

    &::after {
        background: var(--red);
    }
}

.redButton {
    background-color: var(--red);
    & h1 {
        color: var(--night);
    }

    &::after {
        background: var(--night);
    }
}

.blueButton {
    background-color: var(--blue);
    & h1 {
        color: var(--night);
    }

    &::after {
        background: var(--platinum);
    }
}